<template>
  <div class="citizen-charter-wrapper">
    <TopFilter @changeInTopFilter="handleTopFilter"></TopFilter>
    <div class="citizen-charter__content">
      <RadioFilter
        @changeInRadioFilter="handleRadioFilter"
        v-if="showSideBar"
      ></RadioFilter>
      <div class="main-container echarter-main-container">
        <vue-custom-scrollbar class="scroll-area" v-if="charters.length">
          <div class="citizen-charter">
            <div
              class="citizen-charter__cards"
              v-for="(ele, index) in charters"
              :key="index"
              @click="goToDetail(ele.id)"
            >
              <div class="citizen-charter__cards-title">
                {{ ele.servicename }}
              </div>
              <a class="read-more">
                थप जानकारी <unicon name="sign-out-alt"></unicon>
              </a>
            </div>
          </div>
        </vue-custom-scrollbar>
        <NoDataFound v-else />
      </div>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  data() {
    return {
      service_provider: "",
      service_type: "",
      top_filter: "all"
    };
  },
  components: {
    RadioFilter: () => import("./citizenCharterRadioFilter.vue"),
    TopFilter: () => import("./citizenCharterTopFilter.vue"),
    vueCustomScrollbar
  },
  computed: {
    showSideBar: function () {
      if (this.top_filter === "" || this.top_filter === "all") {
        return true;
      }
      return false;
    },
    charters: function () {
      let data = this.$store.state.citizenCharter;
      let fiscalYear = this.$store.state.fiscal_year;

      data = data.filter((ele) => ele.fiscalyear === fiscalYear);

      if (this.service_provider) {
        data = data.filter(
          (ele) => ele.serviceprovider === this.service_provider
        );
      }

      if (this.service_type) {
        data = data.filter((ele) => ele.servicetype === this.service_type);
      }

      if (this.top_filter !== "all") {
        if (this.top_filter === "youth") {
          data = data.filter((ele) => ele.agegroup === "youth_16_40");
        } else {
          data = data.filter((ele) => {
            let wmgs = ele.relatedwmgs ? ele.relatedwmgs.split(", ") : [];
            if (wmgs.length) {
              if (wmgs.includes(this.top_filter)) {
                return ele;
              }
            }
          });
        }
      }

      return data;
    }
  },
  watch: {
    showSideBar: {
      handler(value) {
        if (!value) {
          this.service_provider = "";
          this.service_type = "";
        }
      }
    }
  },
  methods: {
    handleRadioFilter(event) {
      this[event.type] = event.value;
    },
    handleTopFilter(event) {
      this.top_filter = event;
    },
    goToDetail(id) {
      this.$router.push(`/citizen-charter/${id}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.citizen-charter {
  &__content {
    display: flex;
    padding-top: 208px;
    @media screen and (max-width: 3199px) {
      padding-top: 77px;
    }
  }
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 0 100px;
  width: 100%;
  /* width: calc(100vw - 1400px); */

  @media screen and (max-width: 3199px) {
    padding: 0 53px;
  }

  &__cards {
    border: 2px solid $neutrals-midnight;
    border-radius: 20px;
    width: 32%;
    margin-bottom: 40px;
    margin-right: 40px;
    padding: 60px;
    cursor: pointer;

    @media screen and (max-width: 3199px) {
      padding: 22px;
      border-radius: 8px;
      width: 32.1211%;
      margin-right: 1.818%;
    }

    &:hover {
      background-color: $neutrals-nightsky;
      border-color: $neutrals-nightsky;
      color: $neutrals-white;
      .read-more {
        color: $secondary;
      }
    }
    &-title {
      font-size: 56px;
      font-weight: 600;

      @media screen and (max-width: 3199px) {
        font-size: 20px;
      }
      @media screen and (max-width: 1800px) {
        font-size: 18px;
      }
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-last-child(-n + 3) {
      margin-bottom: 0;
    }
    .read-more {
      align-items: center;
      color: $neutrals-nightsky;
      display: flex;

      @media screen and (max-width: 3199px) {
        font-size: 15px;
      }
    }
  }
}

.echarter-main-container {
  height: calc(100vh - 504px);
  position: relative;
  width: 100%;
  z-index: 1;
  @media screen and (max-width: 3199px) {
    height: calc(100vh - 185px);
  }
}

.filter-container + .echarter-main-container {
  width: calc(100% - 600px);
  @media screen and (max-width: 3199px) {
    width: calc(100% - 214px);
  }
}
</style>
